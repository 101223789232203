import React, { useEffect } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectCards } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer"

//Import Swiper Styles
// import "swiper/css";
// import SectionBlock from "../components/SectionBlock";

export default function BlogContent({ blog }) {

  const navigate = useNavigate()

  useEffect(()=>{ 
    const targetElement = document.getElementById("title-blog-content")
    
    if(targetElement){
        targetElement.scrollIntoView({behavior:"smooth"})
      } 
  },[])

  return (
    <>
      <div style={{ maxWidth: "1000px" }} className="m-auto px-3 py-6">
        <h2 className="text-center text-2xl texto" id="title-blog-content">{blog.title}</h2>
        {blog.body.map((e, index) => {
          if (e.type === "text") {
            return (
              <p key={index} className="texto py-3">
                {e.content}
              </p>
            );
          }

          if (e.type === "img") {
            return (
              <img
                key={index}
                src={e.content}
                alt={e.alt}
                className="w-2/3 m-auto rounded-lg shadow-md"
              />
            );
          }

          if (e.type === "img_nav") {
            return (
              <img
                key={index}
                src={e.content}
                alt={e.alt}
                className="w-2/3 m-auto rounded-lg shadow-md cursor-pointer"
                onClick={() => navigate(e.url)}
              />
            );
          }

          if(e.type === "img_list"){
            return (
              <div key={index} className="flex justify-center flex-wrap">
                {e.content.map((e,index2)=><img 
                key={index2}
                src={e.img} alt={e.alt} className="w-80 h-80 m-3 rounded-md shadow-md"/>)}
              </div>
            )
          }

          if (e.type === "text_link") {
            return e.content.map((i) => (
              <p key={index}>
                <span className="font-bold">{i.title}: </span>
                <a
                  className="text-blue-400 underline"
                  href={i.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {i.link}
                </a>
              </p>
            ));
          }

          if(e.type === "text_links"){
            return (<div className="flex flex-wrap my-3" key={index}>
              {e.content.map((i,index2)=>
                <a
                key={index2}
                  className="text-blue-400 underline p-2 m-1 border bg-slate-100 rounded-md shadow-md transition-all hover:scale-95" 
                  href={i.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {i.text}
                </a>)}
            </div>)
          }

          if (e.type === "text_list") {
            return (
              <ul key={index} className="pl-3 ml-3 py-3 list-disc texto">
                {e.content.map((i, index2) => (
                  <li key={index2}>{i}</li>
                ))}
              </ul>
            );
          }

          if (e.type === "video_inst") {
            return (
              <video
              key={index}
                id="myVideo"
                controls
                className="h-96 m-auto rounded-lg shadow"
              >
                <source src={e.content} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            );
          }

          return null

          // if (e.type === "img_carrousel") {
          //   return (
          //     <Swiper key={index}
          //       modules={[EffectCards]}
          //       effect="cards"
          //       className="w-96"
          //     >
          //       {e.content.map((i, index2) => (
          //         <SwiperSlide key={index2}>
          //           <img
          //             className="h-80 w-80 m-auto rounded shadow-md"
          //             src={i.img}
          //             alt={i.alt}
          //           />
          //         </SwiperSlide>
          //       ))}
          //     </Swiper>
          //   );
          // }
        })}
        {/* <SectionBlock blog={blog} /> */}
        
      </div>
      <Footer/>
    </>
  );
}
